.miniNavbar {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;

  padding: 15px;
  color: white;
  background-color: var(--primary-transparent);
  width: 100%;
  backdrop-filter: blur(3px);
}

.miniNavbar svg {
  margin-right: 15px;
  padding: 5px;
  border-radius: 5px;
  height: 25px;
  width: 25px;
  float: left;
  transition: 250ms;
  fill: white !important;
}
.miniNavbar svg path {
  fill: white !important;
}

.miniNavbar svg:hover {
  background: var(--secondary);
  /* background: #004346; */
  transition: 250ms;
  cursor: pointer;
}

.appContent {
  top: 0;
  left: 0;
  padding: 0;
  height: 100vh;
}


/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #00033333 #ffffff00;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #ffffff00;
}

*::-webkit-scrollbar-thumb {
  background-color: #00033333;
  border-radius: 6px;
  border: 3px solid #ffffff00;
}