@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Roboto Mono", monospace;

  overflow: hidden;

  background: var(--primary);

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

#app {
  height: 100%;
  font-size: 1.5em;
}

:root {
  --spacing: 0.4rem;
  --primary: #4678c4;
  --secondary: #53c3f7;
  --tertiary: #003638;

  --primary-transparent: #4678c43a;

  --blue: #2e82f7;
  --orange: #dfa94d;
  --green: #5fcf9c;
  --cyan: #5ac9ca;
}

input[type="range"] {
  height: 10px;
  -webkit-appearance: none;
  margin: 10px 0;
}
input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  height: 30px;
  width: 30px;
  border-radius: 50px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
}

input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000;
  border: 0px solid #000000;
  height: 35px;
  width: 35px;
  border-radius: 50px;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  cursor: pointer;
  transition: 0.2s;
  box-shadow: 1px 1px 1px #000000;
  background: #3071a9;
  border-radius: 15px;
  border: 1px solid #000000;
}
