.levelSelector {
  width: 75px;
  height: 75px;
  border-radius: 5px;
  display: inline-flex;
  margin-right: 10px;
  margin-top: 60px;
  align-items: center;
  justify-content: center;
  color: white;
  border: 1px solid white;
  transition: 250ms;
  cursor: pointer;
}

.levelSelector:hover {
  background: var(--secondary);
  transition: 250ms;
}

.levelSelectorDisabled {
  cursor: default;
  color: darkgray;
  border-color: darkgray;
}
.levelSelectorDisabled:hover {
  background: none;
}

.levelUnlocked {
  background: var(--secondary);
}
.levelUnlocked:hover {
  background: var(--tertiary);
}

.world-map {
  max-width: 1000px;
  padding-top: 10%;
  margin: auto;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0;
  flex-grow: 1;
  align-content: flex-start;

  justify-content: flex-start;

  align-items: center;
}

.appContent,
.level_right {
  background: linear-gradient(-45deg, #00acca, #6a3ce7, #23a6d5, #6a00e2);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
