.level {
  height: 100%;
  display: flex;
}

.level > .level__right,
.level > .level__left > .level__left-script-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.level > .level__left > .level__title {
  color: white;
  margin-top: 0.1rem;
}

.level > .level__left > .level__left-script-container {
  margin: 0 50px 0 0;
}

.level > .level__right {
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.level > .level__right > canvas {
  max-height: 100vh;
  max-width: 100%;
  display: inline;
}

.level > .level__left {
  padding: 20px;
  margin-top: 65px;
  box-sizing: border-box;
  background: var(--primary-transparent);
  backdrop-filter: blur(3px);
  display: inline-block;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 500px;
}

.level > .level__right > .level__prompt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 33%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.level > .level__right > .level__prompt > .level__prompt-text {
  font-size: 3rem;
  display: flex;
  align-items: center;
}

.playAnimationButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;

  padding: 20px 0px;

  background: var(--primary);

  width: 350px;
  height: 30px;

  transition: 250ms;
  border: 1px solid black;
}

.playAnimationButtonContainer:hover {
  background: var(--tertiary);
  transition: 250ms;
}

.playbackSpeedInput {
  margin: 20px;
  position: absolute;
  right: 35px;
}

.playAnimationButton {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 20px 15px;
  border-radius: 5px;
  color: white;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.playAnimationButton:hover {
  cursor: pointer;
  border: 1px solid white;
  transition: 250ms;
}

.level__prompt-ball--orange {
  background: var(--orange);
}

.level__prompt-ball--blue {
  background: var(--blue);
}

.level__prompt-ball--cyan {
  background: var(--cyan);
}

.level__prompt-ball--green {
  background: var(--green);
}

.level__prompt-ball {
  margin-left: 2rem;
  margin-right: 1rem;

  display: inline-block;
  border-radius: 3rem;

  padding: 2rem;

  border: 3px solid black;
}

.playbackSpeedInputDisabled {
  display: none;
}

.playAnimationButtonSpecial {
  width: 100%;
  margin: 0;
  padding: 20px;
  align-items: center;
  justify-content: center;
}

.playAnimationButtonSpecial:hover {
  width: 100%;
  margin: 0;
  align-items: center;
  justify-content: center;
}

.nextLevelPlayContainer {
  position: absolute;
  bottom: 6%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  column-gap: 10px;

  padding: 20px;

  height: 30px;

  transition: 250ms;
}

.prevLevelButton {
  background: var(--primary);
  max-height: 30px;
  padding: 20px;
  border-radius: 5px;
  z-index: 1000;
  border: 1px solid black;
  color: white;
}

.nextLevelButton {
  background: var(--primary);
  max-height: 30px;
  padding: 20px;
  border-radius: 5px;
  z-index: 1000;
  border: 1px solid black;
  color: white;
}

.prevLevelButton:hover,
.nextLevelButton:hover {
  background: var(--tertiary);
  transition: 250ms;
  padding: 19px;
  cursor: pointer;
  border: 1px solid white;
}

.level__success-prompt {
  margin-top: -160px;
  color: white;
}

.level__success-prompt span {
  display: inline-block;
  -webkit-animation: wave-text 1s ease-in-out infinite;
  animation: wave-text 1s ease-in-out infinite;
}

.level__success-prompt span:nth-of-type(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.level__success-prompt span:nth-of-type(2) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.level__success-prompt span:nth-of-type(3) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.level__success-prompt span:nth-of-type(4) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.level__success-prompt span:nth-of-type(5) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.level__success-prompt span:nth-of-type(6) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.level__success-prompt span:nth-of-type(7) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.level__success-prompt span:nth-of-type(8) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
.level__success-prompt span:nth-of-type(9) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.level__success-prompt span:nth-of-type(10) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
.level__success-prompt span:nth-of-type(11) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.level__success-prompt span:nth-of-type(12) {
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s;
}
.level__success-prompt span:nth-of-type(13) {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}
.level__success-prompt span:nth-of-type(14) {
  -webkit-animation-delay: 1.3s;
          animation-delay: 1.3s;
}
.level__success-prompt span:nth-of-type(15) {
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
}
.level__success-prompt span:nth-of-type(16) {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

@-webkit-keyframes wave-text {
  00% {
    transform: translateY(0em);
  }
  60% {
    transform: translateY(-0.4em);
  }
  100% {
    transform: translateY(0em);
  }
}
 
@keyframes wave-text {
  00% {
    transform: translateY(0em);
  }
  60% {
    transform: translateY(-0.4em);
  }
  100% {
    transform: translateY(0em);
  }
}