.home-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.menuButton {
  padding: 10px;
  border-radius: 5px;
  background: var(--secondary);
  margin: 10px;
  min-width: 250px;
  text-align: center;
  transition: 250ms;
  border: 1px solid black;
}

.menuButton:hover {
  background: var(--tertiary);
  transition: 250ms;
  color: white;
  cursor: pointer;
}
